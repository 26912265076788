import React, { useState, useEffect } from 'react';
import { Row, Col, Form, OverlayTrigger, Spinner } from "react-bootstrap";
import styles from "./DashboardFiscalAdjunto.module.css";
import { useKeycloak } from "@react-keycloak/web";
import GaugeChart from 'react-gauge-chart';
import NumberDisplay from '../../../components/charts/NumberDisplay';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} 
from 'chart.js';
import { Bar } from 'react-chartjs-2';
import axios from "axios";
import ActiveCausesDetail from './ActiveCausesDetail';
import ImputationLapse from './ImputationLapse';
import TimeFilter from '../TimeFilter';
import ActiveCauses from './ActiveCauses/ActiveCauses';
import ResolvedCauses from './ResolvedCauses/ResolvedCauses';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const DashboardFiscalAdjunto = () => {

    const [ rangeType, setRangeType ] = useState("year");

    const [ year, setYear ] = useState(new Date().getFullYear());
    const [ month, setMonth ] = useState("01");
    const [ startDate, setStartDate ] = useState(undefined);
    const [ endDate, setEndDate ] = useState(undefined);
    const [ division, setDivision ] = useState(undefined);

    const [ current, setCurrent ] = useState(1);
    const [ indicators, setIndicators ] = useState(undefined);
    const [ causeResolutions, setCauseResolutions ] = useState(undefined);
    const [ causeComplexity, setCauseComplexity ] = useState(undefined);
    const [ complexity, setComplexity ] = useState(undefined);

    const { keycloak } = useKeycloak();  

    const optionsComplexity = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: 'Cantidad de causas por nivel'
          },
        }
    };

    const optionsCauseComplexity = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: 'Complejidad de causas por unidad fiscal',
          },
        },
    }; 

    const optionsResolutionCauses = {
        responsive: true,
        plugins: {
          legend: {
            position: 'top'
          },
          title: {
            display: true,
            text: 'Causas judicializadas por unidad fiscal'
          },
        },
    };        

    const mapResolutions = ( data ) => {
        
        let datasets = [], labels = [];
        let opportunityCriteria = [], onConditionalSuspension = [], abbreviateProcess = [], conciliated = [], imputed = [];


        for(let current of data){
            labels.push(`${current.prosecutor.firstName} ${current.prosecutor.lastName}`);
            opportunityCriteria.push(current.opportunityCriteria);
            onConditionalSuspension.push(current.onConditionalSuspension);
            abbreviateProcess.push(current.abbreviateProcess);
            conciliated.push(current.conciliated);
            imputed.push(current.imputed);
        }
        
        datasets.push({
            label: "Criterio de oportunidad",
            data: opportunityCriteria,
            backgroundColor: '#4CB899'
        });

        datasets.push({
            label: "En suspensión condicional",
            data: onConditionalSuspension,
            backgroundColor: '#91B5CD'
        });

        datasets.push({
            label: "Proceso abreviado",
            data: abbreviateProcess,
            backgroundColor: '#33ACFF'
        });

        datasets.push({
            label: "Conciliada",
            data: conciliated,
            backgroundColor: '#9AD0F5'
        });

        datasets.push({
            label: "Imputada",
            data: imputed,
            backgroundColor: '#EFEF65'
        });        

        return { labels, datasets };
    }

    const mapComplejidad = ( data ) => {
        let labels = ["Baja", "Media", "Alta"];
        let datasets = [
            {
                label: year,
                data: [data.low, data.medium, data.high],
                backgroundColor: ['#28A745', '#FFC107', '#DC3545']
            }
        ];
        return { labels, datasets };
    }

    const mapComplejidadCausas = ( data ) => {
        let datasets = [], labels = [];
        let lows = [], mediums = [], highs = [];

        for(let current of data){
            labels.push(`${current.prosecutor.firstName} ${current.prosecutor.lastName}`);
            lows.push(current.low);
            mediums.push(current.medium);
            highs.push(current.high);
        }
        
        datasets.push({
            label: "Baja",
            data: lows,
            backgroundColor: '#28A745'
        });

        datasets.push({
            label: "Media",
            data: mediums,
            backgroundColor: '#FFC107'
        });

        datasets.push({
            label: "Alta",
            data: highs,
            backgroundColor: '#DC3545'
        });

        return { labels, datasets };        
    }

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    };    

    const lastDayOfMonth = (month, year) => {
        let fecha = new Date(year, month, 1);
        fecha.setDate(fecha.getDate() - 1);
        return fecha.getDate();
    }

    const buildParams = () => {
        let params = "?";
        switch(rangeType){
            case "year":
                params += "start_date=" + year + "-01-01&";
                params += "end_date=" + year + "-12-31";
                break;
            case "month":
                params += "start_date=" + year + "-" + month + "-01&";
                params += "end_date=" + year + "-" + month + "-" + lastDayOfMonth(month, year);
                break;
            case "custom":
                if(startDate != undefined && endDate != undefined){
                    params += "start_date=" + startDate + "&";
                    params += "end_date=" + endDate;
                }
                break;
            default:
                break;
        }
        if(division!=undefined && division != "TODOS"){
            params+="&division="+division;
        }
        return params;
    }

    const findDashboardIndicators = () => {
        axios.get(process.env.REACT_APP_API_URL + "/deputy/prosecutors/dashboard" + buildParams(), prepareHeaders())        
        .then(res => {
            setIndicators(res.data);
        })
        .catch(error => {
            setIndicators(undefined);
        });
    };

    const findDashboardComplexityCauses = () => {
        axios.get(process.env.REACT_APP_API_URL + "/deputy/prosecutors/dashboard/cause/complexity" + buildParams(), prepareHeaders())
        .then(res => {
            setCauseComplexity(res.data.status == 204 ? undefined : mapComplejidadCausas(res.data.prosecutorCauses));
            setComplexity(res.data.status == 204 ? undefined : mapComplejidad(res.data));
        })
        .catch(error => {
            setCauseComplexity(undefined);
        });
    };    

    useEffect(() => {
        if(current == 1){ findDashboardIndicators(); }
        else if(current == 4){ findDashboardComplexityCauses(); }
    }, 
    [current, startDate, endDate, rangeType, month, year, division]);

    return (
        <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
            <Col md={3}>
                <div className={styles.sideBar}>

                    <Row className="mt-2">
                        <div className={`${styles.button} ${current == 1 ? styles.active : ""}`} onClick={(e) => { setCurrent(1); }}>
                            <span>Indicadores</span>
                        </div>
                    </Row>
                    <Row>
                        <div className={`${styles.button} ${current == 2 ? styles.active : ""}`} onClick={(e) => { setCurrent(2); }}>
                            <span>Causas activas</span>
                        </div>
                    </Row>
                    <Row>
                        <div className={`${styles.button} ${current == 3 ? styles.active : ""}`} onClick={(e) => { setCurrent(3); }}>
                            <span>Causas judicializadas</span>
                        </div>
                    </Row>                    
                    <Row>
                        <div className={`${styles.button} ${current == 4 ? styles.active : ""}`} onClick={(e) => { setCurrent(4); }}>
                            <span>Causas según complejidad</span>
                        </div>
                    </Row>           
                    <Row className="mb-3">
                        <div className={`${styles.button} ${current == 5 ? styles.active : ""}`} onClick={(e) => { setCurrent(5); }}>
                            <span>Causas imputadas</span>
                        </div>
                    </Row>                                                 
                </div>
            </Col>
            <Col md={9}>
                <div className={styles.container}>
                {
                    current == 1 ? (
                        <>


                            {
                                indicators ? (
                                    <>
                                        <TimeFilter 
                                            rangeType={rangeType} 
                                            setRangeType={setRangeType}
                                            year={year}
                                            setYear={setYear}
                                            month={month}
                                            setMonth={setMonth}
                                            startDate={startDate}
                                            setStartDate={setStartDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                        />
                                        <Row style={{ justifyContent: "right" }}>
                                            <Form.Group as={Col} md="auto">
                                                <Form.Label className={styles.label} as={Col}>Unidad Fiscal</Form.Label>
                                                <Form.Control as="select" className={styles.input} value={division} onChange={(e) => setDivision(e.target.value)}>
                                                    <option value="TODOS">TODOS</option>
                                                    <option value="1">Unidad 1</option>
                                                    <option value="2">Unidad 2</option>
                                                    <option value="3">Unidad 3</option>
                                                    <option value="4">Unidad 4</option>
                                                    <option value="5">Unidad 5</option>
                                                    <option value="6">Unidad 6</option>
                                                    <option value="7">Unidad 7</option>
                                                    <option value="8">Unidad 8</option>
                                                    <option value="9">Unidad 9</option>
                                                    <option value="10">Unidad 10</option>
                                                    <option value="11">Unidad 11</option>
                                                    <option value="12">Unidad 12</option>
                                                    <option value="13">Unidad 13</option>
                                                    <option value="14">Unidad 14</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </Row>            

                                        <Row className="mb-3">                              
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.imputed / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.imputed} id={99} title={(<>Causas<br></br>imputadas</>)}/>
                                            </Col>
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.accused / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.accused} id={100} title={(<>Causas<br></br>acusadas</>)}/>
                                            </Col>
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.opportunityCriteria / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.opportunityCriteria} id={100} title={(<>Causas<br></br>criterio de oportunidad</>)}/>
                                            </Col>
                                        </Row>      

                                        <Row className="mb-3">                              
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.archived / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.archived} id={99} title={(<>Causas<br></br>archivadas</>)}/>
                                            </Col>
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.conciliated / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.conciliated} id={100} title={(<>Causas<br></br>conciliadas</>)}/>
                                            </Col>
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.investigationProcess / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.investigationProcess} id={100} title={(<>Causas<br></br>en investigación</>)}/>
                                            </Col>
                                        </Row>     

                                        <Row className="mb-3">                              
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.onConditionalSuspension / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.onConditionalSuspension} id={99} title={(<>Causas<br></br>en suspensión condicional</>)}/>
                                            </Col>
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.rejected / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.rejected} id={100} title={(<>Causas<br></br>rechazadas</>)}/>
                                            </Col>
                                            <Col md={4} style={{ margin: "auto"}} className={styles.detailContainer}>
                                                <GaugeChart
                                                    id="open-chart" 
                                                    nrOfLevels={9}
                                                    colors={["#FFC371", "#FF5F6D"]}
                                                    arcWidth={0.3}
                                                    textColor="black"
                                                    percent={indicators.cause.open.total > 0 ? (indicators.cause.abbreviateProcess / indicators.cause.total) : 0}
                                                />
                                                <NumberDisplay value={indicators.cause.abbreviateProcess} id={100} title={(<>Causas<br></br>en proceso abreviado</>)}/>
                                            </Col>
                                        </Row>                           

                                                             
                                    </>
                                )
                                : 
                                (
                                    <Row>
                                        <Spinner animation="grow" role="status" style={{ marginLeft: "auto", marginRight: "auto"}}>
                                            <span className="visually-hidden">Cargando...</span>
                                        </Spinner>                                            
                                    </Row>
                                )
                            }


                        </>
                    )
                    :  <></>
                    
                }
                {
                    current == 2  ? (
                        <ActiveCauses
                            rangeType={rangeType}
                            setRangeType={setRangeType}
                            year={year}
                            setYear={setYear}
                            month={month}
                            setMonth={setMonth}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}                        
                        />
                    )
                    : <></>
                }
                {
                    current == 3 ? (
                        <ResolvedCauses
                            rangeType={rangeType}
                            setRangeType={setRangeType}
                            year={year}
                            setYear={setYear}
                            month={month}
                            setMonth={setMonth}
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}                        
                        />
                    )
                    : <></>
                }
                {
                    current == 4 ? (
                        <>
                            {
                                causeComplexity ? (
                                    <>
                                        <TimeFilter 
                                            rangeType={rangeType} 
                                            setRangeType={setRangeType}
                                            year={year}
                                            setYear={setYear}
                                            month={month}
                                            setMonth={setMonth}
                                            startDate={startDate}
                                            setStartDate={setStartDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                        />                                    
                                        <Row>
                                            <Col md={12}>
                                                <Bar options={optionsCauseComplexity} data={causeComplexity} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <Bar options={optionsComplexity} data={complexity} />
                                            </Col>
                                        </Row>                                                                
                                    </>
                                )
                                : (
                                    <Row>
                                        <Spinner animation="grow" role="status" style={{ marginLeft: "auto", marginRight: "auto"}}>
                                            <span className="visually-hidden">Cargando...</span>
                                        </Spinner>                                            
                                    </Row>                                
                                )
                            }
                        </>
                    )
                    : <></>
                }
                {
                    current == 5 ? <ImputationLapse />
                    : <></>
                }                
                </div>
            </Col>
        </Row>

    )
}

export default DashboardFiscalAdjunto;