export const ComplaintType = {
    ESCRITA: {        
        name: "Escrita"

    },
    RESOLUCION: {      
        name: "Por resolución"
       
    },
    VERBAL: {

        name: "Verbal"
      
    },

};