import React, { useState, useEffect } from "react";
import styles from "./UnidadCauses.module.css";
import { Modal, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

const UnidadCauses = ({ show, callback, handleClose, setDivisionUp }) => {

    const [ loading, setLoading ] = useState(false);
    const [ division, setDivision ] = useState(undefined);
    const [ divisions, setDivisions ] = useState([]);


    const { keycloak } = useKeycloak();

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }      

    const handleRegister = (e) => {
        e.preventDefault();
        let a =divisions.find(divObj => divObj.id=division);
        console.log(a);
        setDivisionUp(divisions.find(divObj => divObj.id=division));
        //callback(e);
        handleClose();
    }

    const findDivisions = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/divisions`, prepareHeaders()).
        then(res => { setDivisions(res.status != 200 ? [] : res.data); }).
        catch(err => { console.log(err); });
    }

    useEffect(() => findDivisions(), []);

    return (
        <>
            <Toaster />
            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title><h5>Reporte Unidad</h5></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label className={styles.label}>Unidad</Form.Label>
                                <Form.Control as="select" className={styles.input} value={division} onChange={(e) => { e.preventDefault(); setDivision(e.target.value); }}>
                                    <option value={0}>Seleccione</option>
                                    {
                                        divisions.map((division) => (
                                            <option value={division.id}>{division.name}</option>
                                        ))
                                    }
                                </Form.Control>
                            </Form.Group>
                        </Row>                                   
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Cancelar</Button>
                    <Button variant="primary" onClick={handleRegister} disabled={loading}>
                        { 
                            loading ? (
                                <>
                                    <Spinner size="sm" animation="border" role="status" />&nbsp;&nbsp;
                                    <span>Guardando...</span>
                                </>
                            )
                            :
                            <span>Descargar</span>
                        }
                    </Button>                    
                </Modal.Footer>
            </Modal>            
        </>
    );

}

export default UnidadCauses;