import React, { useState, useEffect } from "react";
import axios from "axios";
import { AiOutlineSearch } from 'react-icons/ai';
import { AiOutlineClear } from "react-icons/ai";
import {Form, Button, Col, Row, DropdownButton, Dropdown, Badge, Table, InputGroup } from "react-bootstrap";
import { RiSortAsc, RiSortDesc, RiCloseFill, RiTableFill, RiArticleFill } from "react-icons/ri";
import styles from "./Causas.module.css";
import CustomPagination from "../../../components/pagination/CustomPagination";
import { useKeycloak } from "@react-keycloak/web";
import { useNavigate } from "react-router-dom";
import Causa from "./Causa/Causa";
import { CauseStatus } from "../../../constants/CauseStatus";
import { useSearchParams } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import { checkRoles } from "../../../Keycloak";
import { Complexity } from "../../../constants/Complexity";
import Options from "./Causa/Options";
import GenericStatusModal from "./Causa/GenericStatusModal";
import ImputationModal from "./Causa/ImputationModal/ImputationModal";
import DisassociateModal from "./Causa/DisassociateModal/DisassociateModal";
import AssignFiscal from "./Causa/AssignFiscal";
import EditDateModal from "./Causa/EditDateModal";
import CrimesModal from "../../../components/modals/CrimesModal";

const Causas = () => {

    let [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate();

    const [ prosecutors, setProsecutors ] = useState([]);    
    const [prosecutorsAssistants, setProsecutorsAssistants] = useState([]);
    const [ injuredParties, setInjuredParties ] = useState([]);    

    const { keycloak } = useKeycloak();

    const [ view, setView ] = useState("T");

    const [causas, setCausas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(20);
    const [totalPages, setTotalPages] = useState(10);
    const [totalElements, setTotalElements] = useState(0);
    const [id, setId] = useState(undefined);
    const [complaintId, setComplaintId] = useState(searchParams.get("complaint_id"));
    const [lteDate, setLteDate] = useState(undefined);
    const [gteDate, setGteDate] = useState(undefined);
    const [status, setStatus] = useState(undefined);
    const [sorting, setSorting] = useState([]);
    const [ reference, setReference ] = useState(undefined);
    const [causeSeverityLevel, setCauseSeverityLevel] = useState(undefined);
    const [organizedStructure, setOrganizedStructure] = useState(undefined);
    const [immediateAction, setImmediateAction] = useState(undefined);
    const [selectedCrimes, setSelectedCrimes] = useState([]);
    const [prosecutorAssistant, setProsecutorAssistant] = useState({});
    const [showCrimeModal, setShowCrimeModal] = useState(false);

    const [ prosecutor, setProsecutor ] = useState({});
    const [ injuredParty, setInjuredParty ] = useState(undefined);
    const [ name, setName ] = useState(undefined);
    const [ documentNumber, setDocumentNumber ] = useState(undefined);

    const [ cause, setCause ] = useState(undefined);
    const [ showAssign, setShowAssign ] = useState(false);
    const [ showEditDate, setShowEditDate ] = useState(false);
    const [ showConciliateStatus, setShowConciliateStatus ] = useState(false);
    const [ showOnConditionalSuspension, setShowOnConditionalSuspension ] = useState(false);
    const [ showOpportunityCriteria, setShowOpportunityCriteria ] = useState(false);
    const [ showAbbreviateProcess, setShowAbbreviateProcess ] = useState(false);
    const [ showArchive, setShowArchive ] = useState(false);
    const [ showReject, setShowReject ] = useState(false);
    const [ showImputation, setShowImputation ] = useState(false);
    const [ showReopen, setShowReopen ] = useState(false);
    const [ showDisassociate, setShowDisassociate ] = useState(false);    

    const reset = (e) => {
        e.preventDefault();
        setPage(1);
        setId("");
        setComplaintId("")
        setLteDate("");
        setGteDate("");
        setStatus("");
        setSorting([]);
        setProsecutor({});
        setInjuredParty(null);
        setName("");
        setReference("");
        setDocumentNumber("");
    }     

    const prepareHeaders = () => {
        return {
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",                
                "Authorization": `Bearer ${keycloak.token}`,
                "Access-Control-Allow-Origin": "*",
            }
        };
    }

    const findCauses = () => {
        setLoading(true);
        let params = buildParams();
        const URL = `${process.env.REACT_APP_API_URL}/causes?page=${page}&pageSize=${pageSize}${params}`;
        axios.get(URL, prepareHeaders())
        .then(res => {
            setCausas(res.status == 206 ? res.data.content : []);
            setTotalPages(res.status == 206 ? res.data.totalPages : 10);
            setTotalElements(res.status == 206 ? res.data.totalElements : 0);
            setLoading(false);
        })
        .catch(err => {
            setCausas([]);
            setLoading(false);
        });
    }

    const findProsecutors = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/prosecutors`, prepareHeaders())        
        .then(res => { setProsecutors(res.status != 200 ? [] : res.data); })
        .catch(err => { console.log(err); });
    }    
    const findProsecutorsAssistants = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/prosecutors/assistants`, prepareHeaders())        
        .then(res => { setProsecutorsAssistants(res.status != 200 ? [] : res.data); })
        .catch(err => { console.log(err); });
      }

    const findInjuredParties = () => {
        axios.get(`${process.env.REACT_APP_API_URL}/injuredParties`, prepareHeaders())
        .then(res => setInjuredParties(res.status == 204 ? [] : res.data))
        .catch(err => {
            setInjuredParties([]);
        });
    }    

    const buildParams = () => {
        let query = "";
        if(id){ query += `&causeId=${id}` }
        if(complaintId){ query += `&complaintId=${complaintId}` }
        if(lteDate){ query += `&lte_date=${lteDate}` }
        if(gteDate){ query += `&gte_date=${gteDate}` }
        if(status && status != "TODOS"){ query += `&status=${status}` }
        if (causeSeverityLevel && causeSeverityLevel != "TODOS") {query += `&causeSeverityLevel=${causeSeverityLevel}`;}
        if (organizedStructure && organizedStructure != "TODOS") {query += `&organizedStructure=${organizedStructure}`;}
        if (immediateAction && immediateAction != "TODOS") {query += `&immediateAction=${immediateAction}`;}
        if(selectedCrimes.length>0){
           selectedCrimes.map(crime => query+=`&crimes=${crime.id}`);
       
        }
        if(prosecutor.id && prosecutor.firstName != "TODOS"){ query += `&prosecutorId=${prosecutor.id}`; }
        if (prosecutorAssistant.id && prosecutorAssistant.firstName != "TODOS") {query += `&prosecutorAssistantId=${prosecutorAssistant.id}`;}
        if(name && name != ""){ query += `&name=${name}`; }
        if(documentNumber && documentNumber != ""){ query += `&documentNumber=${documentNumber}`; }
        if(injuredParty && injuredParty != "TODOS"){ query += `&institution=${injuredParty}`; }        
        if(reference){ query += `&referenceId=${reference}`; }        
        if(sorting && sorting.length > 0){ sorting.map((sort) => { query += `&sortBy=${sort.key}:${sort.direction}` }) }
        return query;
    }

    const sortingBy = (field, label) => {
        if(sorting.filter((element) => element.key === field).length > 0){
            setSorting(sorting.filter((element) => element.key !== field));
        }
        else {
            setSorting([...sorting, { key: field, label, direction: "ASC" }]);
        }
    }

    const changeDirection = (sort) => {
        let elements = [];
        for(let current of sorting){
            if(current.key === sort.key){
                current.direction = sort.direction === "ASC" ? "DESC" : "ASC";
            }
            elements.push(current);
        }
        setSorting(elements);
    }


    const handleCloseCrimeModal = (e) => {

        setShowCrimeModal(false);
    }

    const isDeputyProsecutor = () => {
        return checkRoles(keycloak) == "fiscal-adjunto";
    }

    const callback = () => { findCauses(); }   

    useEffect(() => findCauses(), [page, pageSize, sorting]);
    useEffect(() => { findCauses(); findProsecutors(); findProsecutorsAssistants(); findInjuredParties(); }, []);
    useEffect(() => setPage(1), [id, complaintId, lteDate, gteDate, status]);
    
    return (
        <>
            <Toaster />
            <GenericStatusModal toast={toast} show={showConciliateStatus} handleClose={() => setShowConciliateStatus(false)} cause={cause} callback={callback} route={"conciliate"} />
            <GenericStatusModal toast={toast} show={showAbbreviateProcess} handleClose={() => setShowAbbreviateProcess(false)} cause={cause} callback={callback} route={"abbreviate/process"} />
            <GenericStatusModal toast={toast} show={showOnConditionalSuspension} handleClose={() => setShowOnConditionalSuspension(false)} cause={cause} callback={callback} route={"conditional/suspension"} />
            <GenericStatusModal toast={toast} show={showOpportunityCriteria} handleClose={() => setShowOpportunityCriteria(false)} cause={cause} callback={callback} route={"opportunity/criteria"} />
            <GenericStatusModal toast={toast} show={showArchive} handleClose={() => setShowArchive(false)} cause={cause} callback={callback} route={"archive"} />
            <GenericStatusModal toast={toast} show={showReject} handleClose={() => setShowReject(false)} cause={cause} callback={callback} route={"reject"} />
            <GenericStatusModal toast={toast} show={showReopen} handleClose={() => setShowReopen(false)} cause={cause} callback={callback} route={"reopen"} />
            <ImputationModal toast={toast} show={showImputation} handleClose={() => setShowImputation(false)} cause={cause} callback={callback} />
            <DisassociateModal toast={toast} show={showDisassociate} handleClose={() => setShowDisassociate(false)} cause={cause} callback={callback} />
            <AssignFiscal toast={toast} show={showAssign} handleClose={() => setShowAssign(false)} cause={cause} callback={callback}/>
            <EditDateModal show={showEditDate} handleClose={() => { setShowEditDate(false); }} callback={callback} cause={cause} />
            <Row style={{marginTop: "2rem", marginBottom: "2rem"}}>
                <Col md={3}>
                    <div className={styles.sideBar}>
                        <Form>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingCauseNumber"
                                            type="number"
                                            value={id}
                                            placeholder="ID de la causa"
                                            className={styles.inputFloating}
                                            onChange={(e) => { setId(e.target.value); }}
                                        />
                                        <label htmlFor="floatingCauseNumber" className={styles.label}>Número de causa</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingComplaintNumber"
                                            type="number"
                                            placeholder="ID de la denuncia"
                                            value={complaintId}
                                            className={styles.inputFloating}
                                            onChange={(e) => { setComplaintId(e.target.value); }}
                                        />
                                        <label htmlFor="floatingComplaintNumber" className={styles.label}>Número de denuncia</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            {
                                isDeputyProsecutor() ? (
                                    <Row style={{ marginBottom: ".5rem" }}>
                                        <Form.Group as={Col}>
                                            <Form.Floating>
                                                <Form.Select id="floatingFiscal" className={styles.inputFloating} value={JSON.stringify(prosecutor)} onChange={(e) => { setProsecutor(JSON.parse(e.target.value))}}> 
                                                    <option value={JSON.stringify({firstName: "TODOS"})} selected>TODOS</option>
                                                    {
                                                        prosecutors.map((prosecutor) => (
                                                            <option value={JSON.stringify(prosecutor)}>{`${prosecutor.firstName} ${prosecutor.lastName} (Unidad ${prosecutor.division ? prosecutor.division : "-"})`}</option>
                                                        ))
                                                    }                                            
                                                </Form.Select>
                                                <label htmlFor="floatingFiscal" className={styles.label}>Fiscal</label>
                                            </Form.Floating>
                                        </Form.Group>                                
                                    </Row>
                                )
                                :
                                <></>
                            }
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Select id="floatingInstitution" className={styles.inputFloating} value={injuredParty} onChange={(e) => { setInjuredParty(e.target.value); }}> 
                                            <option value={"TODOS"} selected>TODOS</option>
                                            {
                                                injuredParties.map((injuredParty) => (
                                                    <option value={injuredParty.name}>{`${injuredParty.name}`}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingInstitution" className={styles.label}>Institución afectada</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingName"
                                            type="text"
                                            placeholder="Nombre del denunciante / denunciado"
                                            className={styles.inputFloating}
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}                                            
                                        />
                                        <label htmlFor="floatingName" className={styles.label}>Nombre del denunciante / denunciado</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingDocumentNumber"
                                            type="text"
                                            placeholder="Número de documento"
                                            className={styles.inputFloating}
                                            value={documentNumber}
                                            onChange={(e) => setDocumentNumber(e.target.value)}                                            
                                        />
                                        <label htmlFor="floatingDocumentNumber" className={styles.label}>Número de documento</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingReference"
                                            type="text"
                                            placeholder="Número de referencia"
                                            className={styles.inputFloating}
                                            value={reference}
                                            onChange={(e) => setReference(e.target.value)}
                                        />
                                        <label htmlFor="floatingReference" className={styles.label}>Nro. SIGEDE</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>                            
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                    <Form.Floating>
                                        <Form.Select id="floatingStatus" className={styles.inputFloating} value={status} onChange={(e) => { e.preventDefault(); setStatus(e.target.value); }}> 
                                            <option value={"TODOS"} selected>TODOS</option>
                                            {
                                                Object.keys(CauseStatus).map( key => (
                                                    <option value={key}>{CauseStatus[key].name}</option>
                                                ))
                                            }
                                        </Form.Select>
                                        <label htmlFor="floatingStatus" className={styles.label}>Estado</label>
                                    </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                <Form.Floating>
                                    <Form.Select
                                    id="floatingCauseSeverityLevel"
                                    className={styles.inputFloating}
                                    value={status}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setCauseSeverityLevel(e.target.value);
                                    }}
                                    >
                                    <option value={"TODOS"} selected>
                                        TODOS
                                    </option>
                                    {Object.keys(Complexity).map((key) => (
                                        <option value={key}>{Complexity[key].name}</option>
                                    ))}
                                    </Form.Select>
                                    <label htmlFor="floatingCauseSeverityLevel" className={styles.label}>
                                    Complejidad
                                    </label>
                                </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                <Form.Floating>
                                    <Form.Select
                                    id="floatingOrganizedStructure"
                                    className={styles.inputFloating}
                                    value={status}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setOrganizedStructure(e.target.value);
                                    }}
                                    >
                                    <option value={"TODOS"} selected>
                                        TODOS
                                    </option>
                                    <option value={"true"} selected>
                                        Sí
                                    </option>
                                    <option value={"false"} selected>
                                        No
                                    </option>
                                    </Form.Select>
                                    <label htmlFor="floatingOrganizedStructure" className={styles.label}>
                                    Estructura Organizada
                                    </label>
                                </Form.Floating>
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: ".5rem" }}>
                            <Form.Group as={Col} onClick={()=>setShowCrimeModal(true)}>
                                <Form.Floating>
                                    <Form.Select
                                    id="floatingCrime"
                                    className={styles.inputFloating}
                                    
                                    >
                                    </Form.Select>
                                    <label htmlFor="floatingCrime" className={styles.label}>
                                    Hechos Punibles
                                    </label>
                                </Form.Floating>
                                </Form.Group>
                            <CrimesModal showModal={showCrimeModal} handleClose={handleCloseCrimeModal} updateSelectedCrimes={setSelectedCrimes}/>
                           
                            </Row>
                                    
                            <Row style={{ marginBottom: ".5rem" }}>
                                <Form.Group as={Col}>
                                <Form.Floating>
                                    <Form.Select
                                    id="floatingImmediateAction"
                                    className={styles.inputFloating}
                                    value={immediateAction}
                                    onChange={(e) => {
                                        e.preventDefault();
                                        setImmediateAction(e.target.value);
                                    }}
                                    >
                                    <option value={"TODOS"} selected>
                                        TODOS
                                    </option>
                                    <option value={"true"} selected>
                                        Sí
                                    </option>
                                    <option value={"false"} selected>
                                        No
                                    </option>
                                    </Form.Select>
                                    <label htmlFor="floatingImmediateAction" className={styles.label}>
                                    De Acción Inmediata
                                    </label>
                                </Form.Floating>
                                </Form.Group>
                            </Row>

                            <Row style={{ marginBottom: "1rem" }}>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingGteDate"
                                            className={styles.input} 
                                            type="date" 
                                            value={gteDate} 
                                            onChange={(e) => { setGteDate(e.target.value); }}
                                        />
                                        <label htmlFor="floatingGteDate" className={styles.label}>Fecha inicio</label>
                                    </Form.Floating>
                                </Form.Group>
                                <Form.Group as={Col} md={6}>
                                    <Form.Floating>
                                        <Form.Control 
                                            id="floatingLteDate"
                                            className={styles.input} 
                                            type="date" 
                                            value={lteDate} 
                                            onChange={(e) => { setLteDate(e.target.value); }}
                                        />                                        
                                        <label htmlFor="floatingLteDate" className={styles.label}>Fecha fin</label>
                                    </Form.Floating>                                        
                                </Form.Group>
                            </Row>
                            <Row style={{ marginBottom: "1rem" }}>
                                <Col style={{ textAlign: "right", marginTop: "auto", display: "flex" }}>
                                    <DropdownButton bsPrefix={styles.secondary} title="Ordenar" style={{ marginLeft: "auto" }}>
                                        <Dropdown.Item onClick={(e) => sortingBy("cause_id", "Identificador")} className={styles.item}>ID de la causa</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("created_at", "Fecha de recepción")} className={styles.item}>Fecha de recepción</Dropdown.Item>
                                        <Dropdown.Item onClick={(e) => sortingBy("status", "Estado")} className={styles.item}>Estado</Dropdown.Item>
                                    </DropdownButton>&nbsp;&nbsp;
                                    <Button onClick={reset}  bsPrefix={styles.primary}>Limpiar <AiOutlineClear /></Button>&nbsp;&nbsp;
                                    <Button onClick={() => findCauses()} bsPrefix={styles.primary}>Buscar <AiOutlineSearch /></Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
                <Col md={9}>
                    <div className={styles.container}>
                        <h2>Causas</h2>
                        {
                            loading ? <span className="mt-3 mb-2">Buscando...</span>
                            : 
                            (!loading && (!causas || causas.length == 0)) ? <span className="mt-3 mb-2">No hay resultados</span>
                            :
                            (
                                <>
                                    <Row style={{ flexDirection: "row-reverse" }}>
                                        <Col md="auto" style={{ textAlign: "right" }}>
                                            <InputGroup style={{ flexWrap: "wrap", alignItems: "stretch", justifyContent: "flex-end" }}>
                                                <RiTableFill style={{ marginTop: "auto" }} className={ view == "T" ? styles.iconActive : styles.iconInactive } onClick={(e) => { e.preventDefault(); setView("T"); setPageSize(20); setPage(1); }} />
                                                &nbsp;
                                                <RiArticleFill style={{ marginTop: "auto" }} className={ view == "C" ? styles.iconActive : styles.iconInactive } onClick={(e) => { e.preventDefault(); setView("C"); setPageSize(2); setPage(1); }}/>
                                            </InputGroup>
                                        </Col>                                        
                                        <Col md="auto" style={{ textAlign: "right" }}>
                                            {
                                                sorting.map((sort) => (
                                                    <>
                                                        <Badge className={styles.sortItem}>
                                                            {sort.label}
                                                            &nbsp;
                                                            {
                                                                sort.direction === "ASC"
                                                                ? <RiSortAsc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/> 
                                                                : <RiSortDesc style={{ cursor: "pointer" }} onClick={(e) => { changeDirection(sort) }}/>
                                                            }
                                                            &nbsp; 
                                                            <RiCloseFill style={{ cursor: "pointer" }} onClick={(e) => {sortingBy(sort.key)}}/>
                                                        </Badge>&nbsp;
                                                    </>
                                                ))
                                            }
                                        </Col>
                                    </Row>
                                    <Row style={{ marginTop: ".5rem", marginBottom: "1.5rem" }}>
                                        <Col md={6}>
                                            <CustomPagination page={page} pageSize={pageSize} changePageSize={setPageSize} toPage={(page) => { setPage(page); }} totalPages={totalPages}/>
                                        </Col>                                        
                                        <Col md={6} style={{ textAlign: "right", marginTop: "auto" }}>
                                            <span>{totalElements} {totalElements > 1 ? "causas" : "causa"}</span>
                                        </Col>
                                    </Row>
                                    { 
                                        view == "T" ? (
                                            <Table className={styles.table}>
                                                <thead>
                                                    <th>N° causa</th>
                                                    <th>N° denuncia</th>
                                                    <th>Carátula</th>
                                                    <th>Estado</th>
                                                    <th>Complejidad</th>
                                                    <th></th>
                                                </thead>
                                                <tbody>
                                                    {
                                                        causas.map((cause) => (
                                                           <tr>
                                                                <td>{cause.id}</td>
                                                                <td>{cause.complaint.id}</td>
                                                                <td>{cause.cover}</td>
                                                                <td>{CauseStatus[cause.status].name}</td>
                                                                <td>
                                                                    <Badge pill className={styles.complexityLevel} bg={cause.causeSeverityLevel === "LOW" ? "success" : (cause.causeSeverityLevel === "MEDIUM" ? "warning" : "danger")}>{`${Complexity[cause.causeSeverityLevel].name}`}</Badge>
                                                                </td>
                                                                <td>
                                                                    <div style={{ display: "flex"}}>
                                                                        <Button bsPrefix={styles.next} onClick={(e) => { navigate(`/causas/${cause.id}`) }}>VER</Button>
                                                                        <Options 
                                                                            cause={cause} 
                                                                            callback={() => { findCauses(); }}
                                                                            style={styles.option} 
                                                                            item={styles.optionItem} 
                                                                            setShowEditDate={(value) => { setCause(cause); setShowEditDate(value); }}
                                                                            setShowAssign={(value) => { setCause(cause); setShowAssign(value); }}
                                                                            setShowArchive={(value) => { setCause(cause); setShowArchive(value); }}
                                                                            setShowReject={(value) => { setCause(cause); setShowReject(value); }}
                                                                            setShowImputation={(value) => { setCause(cause); setShowImputation(value); }}
                                                                            setShowOpportunityCriteria={(value) => { setCause(cause); setShowOpportunityCriteria(value); }}
                                                                            setShowOnConditionalSuspension={(value) => { setCause(cause); setShowOnConditionalSuspension(value); }}
                                                                            setShowAbbreviateProcess={(value) => { setCause(cause); setShowAbbreviateProcess(value); }}
                                                                            setShowConciliateStatus={(value) => { setCause(cause); setShowConciliateStatus(value); }}
                                                                            setShowReopen={(value) => { setCause(cause); setShowReopen(value); }}
                                                                            setShowDisassociate={(value) => { setCause(cause); setShowDisassociate(value); }}
                                                                        />                                                                    
                                                                    </div>
                                                                </td>
                                                           </tr> 
                                                        ))
                                                    }
                                                </tbody>
                                            </Table>
                                        )
                                        :
                                        (<>{ causas.map((cause) => ( <Causa callback={() => { findCauses(); }} cause={cause}/> )) }</>)
                                    }
                                </>
                            )
                        }                
                    </div>                    
                </Col>
            </Row>
        </>
    )
}

export default Causas;
  